import { LASTLINK_CLICK_START,LASTLINK_CLICK_SUCCESS,LASTLINK_CLICK_FAILURE, GET_LAST_CLICKED_LINK } from '../actions/actionTypes';

const initialState = {
    dataStart:false,
    dataSuccess:null,
    error: null,
  };


const LastLinkClicked = (state = initialState, action) => {

    switch (action.type) {
       case LASTLINK_CLICK_START:
       return{
            ...state,
            dataSuccess:null,
            dataStart:true,
            error:null
       }
       case LASTLINK_CLICK_SUCCESS:
        return{
           ...state,
            dataSuccess:action.payload,
            dataStart:false,
            error:null,
            getLastLinkDataIsEmpty:false,
        }
        case LASTLINK_CLICK_FAILURE:
        return{
           ...state,
            dataSuccess:null,
            dataStart:false,
            error:action.payload
        }
        case GET_LAST_CLICKED_LINK:
           return{ ...state,
            lastClickedLinkData:action.payload,
           }

       default:
        return state;
    

    }
}


export default LastLinkClicked;
