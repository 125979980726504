import {VERIFICATION_LINKPOC_START,VERIFICATION_LINKPOC_SUCCESS,VERIFICATION_LINKPOC_FAILURE} from '../actions/actionTypes';

const initialState = {
    verificationPocStart: false,
    verificationPocData:null,
    error: null
  };

const VerificationLinkPoc =(state = initialState, action) => {
    switch (action.type) {
        case VERIFICATION_LINKPOC_START:
            return {
               ...state,
               verificationPocStart: true,
               verificationPocData: null,
               error:null
            };
        case VERIFICATION_LINKPOC_SUCCESS:
            return {
               ...state,
                verificationPocStart: false,
                verificationPocData: action.payload,
                error:null
            };
        case VERIFICATION_LINKPOC_FAILURE:
            return {
               ...state,
               verificationPocStart:false,
               verificationPocSuccess: null,
               error: action.payload
            };
        default:
            return state;
    }


}


export default VerificationLinkPoc




