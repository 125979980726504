import React, { useState } from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import "./indexTemplate.css";

const TechnicalError = ({ modalColor }) => {
  const [showPopUp, setShowPopUp] = useState(true);
  const TechnicalErrorIconSvg = ({ color }) => (
    <svg
      style={{ margin: "1rem auto", width: "3rem", height: "3rem" }}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill={color} />
      <path
        d="M13.5089 7.5H16.5177L16.0118 18.0685H13.9882L13.5089 7.5ZM15.0266 23.0769C14.5296 23.0769 14.1124 22.9445 13.7751 22.6798C13.4379 22.4003 13.2692 22.062 13.2692 21.6649C13.2692 21.2677 13.4379 20.9367 13.7751 20.672C14.1124 20.3925 14.5296 20.2528 15.0266 20.2528C15.5237 20.2528 15.9319 20.3925 16.2515 20.672C16.571 20.9367 16.7308 21.2677 16.7308 21.6649C16.7308 22.062 16.5621 22.4003 16.2248 22.6798C15.9053 22.9445 15.5059 23.0769 15.0266 23.0769Z"
        fill="#FCFBFB"
      />
    </svg>
  );

  const handleOnClick = () => {
    setShowPopUp(false);
  };
  return (
    showPopUp && (
      <div
        className="card-body  text-centered p-4 rounded-2"
        style={{
          width: "475px",
        }}
      >
        <TechnicalErrorIconSvg
          color={modalColor ? "var(--modal-color)" : "var(--danger-color)"}
        />
        <h4
          className="text-red mb-4 fw-bolder ws-normal-m"
          style={{ color: modalColor }}
        >
          Sorry !
        </h4>
        <h5
          className="text-red mb-5 fw-bolder ws-normal-m"
          style={{ color: modalColor }}
        >
          {" "}
          There was a technical error
        </h5>
        <Button
          className=" upload-button font-bold ws-normal-m mb-3"
          style={{
            background: modalColor
              ? "var(--modal-color)"
              : "var(--default-color)",
          }}
          onClick={handleOnClick.bind(this)}
        >
          Try Again
        </Button>
      </div>
    )
  );
};

export default TechnicalError;
