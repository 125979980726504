import React, { useState } from "react";
import {useDispatch,useSelector} from "react-redux";
import { Modal, ModalBody, Button } from "reactstrap";
import PrivacyTemplate from './privacy.template'
import "./indexTemplate.css";
import ExitScreenRedirect from './exitScreenRedirect.template'
import {updateSession} from '../../helpers/updateSessionHandler'

const ExitScreen = ({ modalColor }) => {
  const dispatch = useDispatch();
  const [showPopUp , setShowPopUp] = useState(true);
  const [showPreviousPage, setShowPreviousPage] = useState(false);
  const [showExitScreenRedirect, setShowExitScreenRedirect] = useState(false);
  const lastLinkedClickedData = useSelector(state => state?.lastLinkClicked?.dataSuccess)
  const sessionId = lastLinkedClickedData?.inviteid

  var updateUserExit = {
    sessionid: sessionId,
    data: {
      indicator: {
        progress_no: "10",
        progress_name: "User exit",
        Detection: "upload",
      },
    },
  };
  
  const TechnicalErrorIconSvg = ({ color }) => (
    <svg
      style={{ margin: "1rem auto", width: "3rem", height: "3rem" }}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill={color} />
      <path
        d="M13.5089 7.5H16.5177L16.0118 18.0685H13.9882L13.5089 7.5ZM15.0266 23.0769C14.5296 23.0769 14.1124 22.9445 13.7751 22.6798C13.4379 22.4003 13.2692 22.062 13.2692 21.6649C13.2692 21.2677 13.4379 20.9367 13.7751 20.672C14.1124 20.3925 14.5296 20.2528 15.0266 20.2528C15.5237 20.2528 15.9319 20.3925 16.2515 20.672C16.571 20.9367 16.7308 21.2677 16.7308 21.6649C16.7308 22.062 16.5621 22.4003 16.2248 22.6798C15.9053 22.9445 15.5059 23.0769 15.0266 23.0769Z"
        fill="#FCFBFB"
      />
    </svg>
  );

  const handleOnClickExit =()=>{
    dispatch(updateSession(updateUserExit,sessionId));
    setShowPopUp(false);
    setShowExitScreenRedirect(true);
  }

  const handleOnClickCancel  = () =>{
    setShowPreviousPage(true);
  }

  if (showPreviousPage) {
    return <PrivacyTemplate modalColor={modalColor}/>;
  }
  if(showExitScreenRedirect){
    return <ExitScreenRedirect modalColor={modalColor}/>;
  }

  return (

    (showPopUp && (
    <Modal
      isOpen={true}
      className={"modal-centered "}
      backdropClassName="custom-backdrop"
      
      style={{ fontFamily: "Montserrat", "--modal-color": modalColor }}
      keyboard={false}
      backdrop={true}
      size="md"
      scrollable={true}
      
    >
      <ModalBody className="text-center">
        <TechnicalErrorIconSvg
          color={modalColor ? "var(--modal-color)" : "var(--danger-color)"}
        />
        <h5
          className="text-red mb-4 fw-bolder ws-normal-m"
          style={{ color: modalColor
            ? "var(--modal-color)"
            : "var(--default-checkSvg-color)",
        }}
        m
        >
          Do you want to exit ?
        </h5>
        {/* <h5
          className="text-red mb-5 fw-bolder ws-normal-m"
          style={{ color: modalColor }}
        >
          {" "}
          There was a technical error
        </h5> */}
        <div className="btn-upload" style={{display:"flex",justifyContent: "center",alignItems: "baseline"}}>
            <Button
              className="upload-button mb-3"
              style={{
                background: "transparent",
                color: modalColor
                  ? "var(--modal-color)"
                  : "var(--default-color)",
                border: "2px solid",
                fontWeight: "bolder", 
                margin:"1rem 1rem 1rem 1rem",
                width: "10rem",
                height: "2.5rem"
              }}
              onClick={handleOnClickExit.bind(this)}
            >
              Exit
            </Button>
          <Button
            className="upload-button"
            style={{
                backgroundColor: modalColor
                  ? "var(--modal-color)"
                  : "var(--default-color)",
                border: "2px solid",
                fontWeight: "bolder",
                marginTop: "2.5rem",
                margin:"1rem 1rem 1rem 1rem",
              }}
            onClick={handleOnClickCancel.bind(this)} 
          >
            Cancel
          </Button>

            </div>



        {/* <Button
          className=" upload-button font-bold ws-normal-m mb-3"
          style={{
           marginTop: "10px",
           marginBottom: "10px",
            fontWeight:"700",
            background: "transparent",
            border:{modalColor} ? `1px solid ${modalColor}` : "5px solid --default-color"}
          }
          onClick={handleOnClickExit.bind(this)}
        >
          Exit
        </Button>
        <Button
          className=" upload-button font-bold ws-normal-m mb-3"
          style={{
            marginTop: "10px",
           marginBottom: "10px",
           fontWeight:"700",
          }}
          onClick={handleOnClickCancel.bind(this)}
        >
          Cancel
        </Button> */}
      </ModalBody>
    </Modal>
    ))
  );
};

export default ExitScreen;
