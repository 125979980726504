// uploadReducer.js
import {
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  IS_VERIFYING,
  PARAMS,
  UNSUPPORTED_MEDIA,
  FILE_ALREADY_EXISTS,
  DOCUMENT_NOT_ORIGINAL,
  BUTTONID_IS_NULL,
  REDIRECT_TO_UPLOAD,
  SOMETHING_WENT_WRONG,
  PASSWORD_PROTECTED,
  CORRUPT_FILE,
  LIMIT_EXCEED,
} from "../actions/actionTypes";

const initialState = {
  uploadedFile: null,
  isVerifying: false,
  error: null,
  params: [],
  documentIsVerified: false,
  documentIsWrong: false,
  unsupportedMedia: false,
  fileAlreadyExists: false,
  documentIsNotOriginal: false,
  buttonIdIsNull: false,
  somethingWentWrong: false,
  passwordProtected: false,
  corruptFile:false,
  limitExceed: false,
};

const uploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case PARAMS:
      return {
        ...state,
        params: action.payload,
      };
    case UPLOAD_FILE_SUCCESS:
      
      return {
        ...state,
        uploadedFile: action.payload,
        isVerifying: false,
        documentIsVerified: true,
        documentIsNotOriginal: false,
        documentIsWrong: false,
        error: null,
      };
    case UPLOAD_FILE_FAILURE:
      
      return {
        ...state,
        isVerifying: false,
        documentIsVerified: false,
        documentIsWrong: true,
        error: action.payload,
      };
    case IS_VERIFYING:
      
      return {
        ...state,
        isVerifying: action.payload,
      };
    case UNSUPPORTED_MEDIA:
      
      return {
        ...state,
        unsupportedMedia: action.payload,
        documentIsVerified: false,
        documentIsNotOriginal: false,
        isVerifying: false,
      };
    case FILE_ALREADY_EXISTS:
      
      return {
        ...state,
        fileAlreadyExists: action.payload,
      };

    case DOCUMENT_NOT_ORIGINAL:
      
      return {
        ...state,
        // isVerifying:false,
        documentIsVerified: false,
        documentIsNotOriginal: action.payload,
      };

    case BUTTONID_IS_NULL:
      
      return {
        ...state,
        buttonIdIsNull: true,
      };

    case REDIRECT_TO_UPLOAD:
      
      return {
        ...state,
        documentIsNotOriginal: false,
        documentIsVerified: false,
      };

    case SOMETHING_WENT_WRONG:
      
      return {
        ...initialState,
        somethingWentWrong: action.payload,
      };

    case PASSWORD_PROTECTED:
      
      return {
        ...state,
        passwordProtected: action.payload,
        documentIsVerified: false,
        documentIsNotOriginal: false,
        isVerifying: false,
      };

      case CORRUPT_FILE:
      
      return {
        ...state,
        corruptFile: action.payload,
        documentIsVerified: false,
        documentIsNotOriginal: false,
        isVerifying: false,
      };

      case LIMIT_EXCEED:
      
      return {
        ...state,
        limitExceed: action.payload,
        documentIsVerified: false,
        documentIsNotOriginal: false,
        isVerifying: false,
      };

    default:
      return state;
  }
};

export default uploadReducer;