import { ONESHOTUPLOAD_START,ONESHOTUPLOAD_SUCCESS,ONESHOTUPLOAD_FAILURE } from '../actions/actionTypes';

const initialState = {
    oneShotSuccess:null,
    oneShotStart:false,
    error: null
    
  };



const OneShotUpload = (state = initialState, action) => {

    switch (action.type) {
       case ONESHOTUPLOAD_START:
       return{
            ...state,
            oneShotSuccess:null,
            oneShotStart:true,
            error:null
       }
       case ONESHOTUPLOAD_SUCCESS:
        return{
           ...state,
            oneShotSuccess:action.payload,
            oneShotStart:false,
            error:null
        }
        case ONESHOTUPLOAD_FAILURE:
        return{
           ...state,
            oneShotSuccess:null,
            oneShotStart:false,
            error:action.payload
        }

       default: return state
    }}


export default OneShotUpload;