import React from "react";
import UploadFile from "../UploadFile";
import { useSelector } from "react-redux";
import doneIcon from "../../../images/ic_done2.png";
import errorIcon from "../../../images/ic_error.png";
import "./index.css";

const VerifiedResponse = () => {
  const verifiedData = useSelector((state) => state.upload?.uploadedFile);

  const data = [
    { field: "Document ID", key: "docid" },
    { field: "Generated On", key: "generatedon" },
    { field: "Document fingerprint", key: "hash" },
    { field: "Original Source", key: "originalsource" },
  ];

  return (
    <div>
      <div className="verified-res-heading">
        {!verifiedData?.error ? (
          <>
            {" "}
            <img src={doneIcon} className="verified-res-img" />
            <div
              className="verified-res-success-heading-text"
              style={{ fontFamily: "Montserrat" }}
            >
              The original document is successfully verified!
            </div>
          </>
        ) : (
          <>
            {" "}
            <img src={errorIcon} className="verified-res-img" />
            <div
              className="verified-res-error-heading-text"
              style={{ fontFamily: "Montserrat" }}
            >
              Unable to verify this document
            </div>
          </>
        )}
      </div>
      <div className="verified-res-content">
        {verifiedData?.error ? (
          <div>
            <div style={{ fontFamily: "Montserrat" }}>Document fingerprint:</div>
            <div
              className="verified-res-error-message"
              style={{ fontFamily: "Montserrat" }}
            >
              This document is not original
            </div>
          </div>
        ) : (
          <div className="verified-res-fields-wrap">
            <div
              className="verified-res-fields"
              style={{ fontSize: "18px", fontFamily: "Montserrat" }}
            >
              Document
            </div>
            <div>
              {verifiedData &&
                data?.map((item, index) => {
                  return (
                    <div className="verified-res-fields" key={index}>
                      <div
                        className="verified-res-field-title"
                        style={{
                          fontSize: "14px",
                          fontWeight: 600,
                          fontFamily: "Montserrat",
                        }}
                      >
                        {item.field}:
                      </div>
                      <p
                        className="verified-res-field-value"
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          // fontFamily: "Montserrat",
                          fontFamily:"Montserrat"
                        }}
                      >
                        {item?.key === "generatedon"
                          ? String(
                              new Date(parseInt(verifiedData?.data[item?.key]))
                            )
                          : verifiedData?.data[item?.key]}
                      </p>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
      {/* <UploadFile /> */}
    </div>
  );
};

export default VerifiedResponse;
