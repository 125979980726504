import { UPLOAD_S3_FILE_FAILURE,UPLOAD_S3_FILE_START,UPLOAD_S3_FILE_SUCCESS } from '../actions/actionTypes';

const initialState = {
    uploading: false,
    uploadSuccess: null,
    uploadError: null,
  };

  const uploadS3Reducer = (state = initialState, action) => {
    switch (action.type) {
      case UPLOAD_S3_FILE_START:
        return { 
            ...state, 
            uploading: true, 
            uploadSuccess: null, 
            uploadError: null 
        };
      case UPLOAD_S3_FILE_SUCCESS:
        return { 
            ...state, 
            uploading: false, 
            uploadSuccess: action.payload, 
            uploadError: null 
        };
      case UPLOAD_S3_FILE_FAILURE:
        return { 
            ...state, 
            uploading: false, 
            uploadSuccess: false, 
            uploadError: action.payload 
        };
      default:
        return state;
    }
  };
  
  export default uploadS3Reducer;