import React from "react";
import { useSelector } from "react-redux";
// import { Modal, ModalBody } from "reactstrap";
import "./indexTemplate.css";
import "../../index.css";
import "./indexTemplate.css";

const Verifying = ({ modalColor }) => {
  const lastLinkedClickedData = useSelector(
    (state) => state?.lastLinkClicked?.dataSuccess
  );
  const sessionId = lastLinkedClickedData?.inviteid;
  return (
    <div
      className="card-body  text-centered p-4 rounded-2"
      style={{
        width: "475px",
      }}
    >
      <div className="loading mt-3 mb-3">
        <div className="lds-ellipsis">
          <div
            style={{
              background: modalColor
                ? "var(--modal-color)"
                : "var(--default-color)",
            }}
          ></div>
          <div
            style={{
              background: modalColor
                ? "var(--modal-color)"
                : "var(--default-color)",
            }}
          ></div>
          <div
            style={{
              background: modalColor
                ? "var(--modal-color)"
                : "var(--default-color)",
            }}
          ></div>
          <div
            style={{
              background: modalColor
                ? "var(--modal-color)"
                : "var(--default-color)",
            }}
          ></div>
        </div>
      </div>
      <h5
        className="mb-3 fw-bold "
        style={{ color: modalColor, lineHeight: "1", letterSpacing: "2px" }}
      >
        Verifying...
      </h5>

      <p
        style={{
          marginLeft: "85%",
          fontSize: "10px",
          marginTop: "1rem",
          marginBottom: "10px",
          width: "5rem",
          marginRight: "10rem",
        }}
      >
        {sessionId}
      </p>
    </div>
  );
};

export default Verifying;
