import React from 'react';
import {useSelector} from 'react-redux'
import { Container, Row, Col } from 'reactstrap';
import img1 from "../../assets/logo_diro.svg";
import 'bootstrap/dist/css/bootstrap.min.css'; 
import "./index.css";

const HeaderComponent = () => {
  const lastLinkedClickedData = useSelector(state => state?.lastLinkClicked?.dataSuccess)
  const sessionId = lastLinkedClickedData?.inviteid

  return (
    <div style={{backgroundColor:"#f7f7f7",lineHeight: "var(--bs-body-line-height)",boxSizing: "border-box"}}>
      <header className="header" style={{padding:"20px",backgroundColor:"white"}}>
        <Container>
          <Row className='header-text-wrap'>
            <Col style={{paddingRight:"6rem"}}>
              <img src={img1}  className="Combined-Shape" alt="Logo" />
            </Col>
        
          <Col  className="offset-md-4" style={{fontFamily: 'Montserrat, sans-serif',fontSize:"20px",fontWeight:"600",float:"right"}}>
          {!sessionId &&(
              <span className="text float-right" style={{color:"rgb(0, 188, 212)"}}>100% Original. <span className="text-dark">Guaranteed</span></span>
            )}
              </Col>
        
          </Row>
        </Container>
      </header>
      </div>
  );
};

export default HeaderComponent;
