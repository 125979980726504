import { PDFFILE_BASE64_DATA,PDFFILE_BASE64_DATA_FAILURE } from '../actions/actionTypes';

const initialState = {
    data: null,
    error: null
    
  };

const PdfData = (state = initialState, action) => {

    switch (action.type) {
        case PDFFILE_BASE64_DATA:
        return{
            ...state,
            data: action.payload,
            error: null
        }
        case PDFFILE_BASE64_DATA_FAILURE:
            return{
               ...state,
                data: null,
                error: action.payload
            }
        default: return state
    }

  }


  export default PdfData;

