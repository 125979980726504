import { env as environment } from "../environments";
import axios from "axios";

export const sendLogs = async (shortmsg, longmsg, facility, sessionId,level) => {
  try {
    var logMessage = {
      version: "1.0",
      host: "upload",
      short_message: shortmsg,
      full_message:longmsg,
      timestamp: Date.now() / 1000,
      level: level,
      facility: facility,
      _sessionid: sessionId,
      app_name: "upload",
      _ENV:environment.env,
    //   tsuser: $rootScope.tsuser,
    //   internet_speed: internetspeedonguac,
    };
    await axios.post(environment.logUrl, logMessage);
  } catch (err) {
    
    // dispatch({
    // 	type: ORG_ERROR,
    // 	payload: err.response,
    // });
  }
};
