import axios from "axios";
import { env as environment } from "../environments";
import {
  UPLOAD_S3_FILE_START,
  UPLOAD_S3_FILE_SUCCESS,
  UPLOAD_S3_FILE_FAILURE,
  LIVEFEEDBACK_START,
  LIVEFEEDBACK_SUCCESS,
  LIVEFEEDBACK_FAILURE,
} from "./actionTypes";
import { updateSession } from "../helpers/updateSessionHandler";
import { sendLogs } from "../helpers/sendLogs";
import { sendSentryLogs } from "../helpers/sentry_logs";

const AWS = require("aws-sdk");
let awsCreds = {};

if (environment?.env === "prodeu") {

  awsCreds = {
    region: process.env.REACT_APP_S3_REGION_PROD,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID_PROD,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY_PROD,
  };
} else {

  awsCreds = {
    region: process.env.REACT_APP_S3_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  };
}

AWS.config.update(awsCreds);
const Aws_S3 = new AWS.S3();

export const s3fileHandler = (file, sessionId, category) => {
  const uploadParams = {
    Bucket:
      environment?.env === "prodeu"
        ? process.env.REACT_APP_S3_BUCKET_PROD
        : process.env.REACT_APP_S3_BUCKET,
    Key: "",
    Body: file,
    ContentType: "application/pdf",
  };

  let datetime = new Date().getTime().toString();
  uploadParams.Key = sessionId + "-" + datetime + ".pdf";

  return async (dispatch) => {
    try {
      dispatch({ type: UPLOAD_S3_FILE_START });

      const s3Promise = new Promise((resolve, reject) => {
        Aws_S3.upload(uploadParams, function (err, data) {
          if (err) {
            var SentryLogMessage = {
              version: "1.0",
              host: "pdf verifier upload Process",
              short_message: "Failed S3 file upload",
              full_message: `S3 Bucket File upload failed.`,
              timestamp: Date.now() / 1000,
              level: 3,
              facility: "uplaods3_Action.js",
              _sessionid: sessionId,
              app_name: "pdf upload Verifier ",
              _ENV: "stage2",
            };
            reject(err);
            sendLogs(
              "S3 file upload",
              "S3 Bucket File upload failed",
              "uplaods3_Action.js",
              sessionId,
              "4"
            );
            sendSentryLogs(SentryLogMessage);
          } else {
            resolve(data);
            dispatch({ type: UPLOAD_S3_FILE_SUCCESS, payload: data });
            sendLogs(
              "S3 file upload",
              "S3 Bucket File upload Success.",
              "uplaods3_Action.js",
              sessionId,
              "4"
            );
          }
        });
      });

      const liveFeedbackPromise = (async () => {
        if (category === "bank" || category === "address") {
          dispatch({ type: LIVEFEEDBACK_START });

          const Header = {
            "Content-Type": "application/json",
          };
          const liveFeedbackForm = new FormData();
          liveFeedbackForm.append("file", file, uploadParams.Key);
          liveFeedbackForm.append("category", category);
          liveFeedbackForm.append("sessionid", sessionId);

          const response = await axios.post(
            environment.getProcessedDataFromChatGpt,
            liveFeedbackForm,
            Header
          );
          var send_livefeedback = {
            sessionid: sessionId,
            data: { livefeedback: response.data },
          };
          dispatch(updateSession(send_livefeedback, sessionId));
          if (
            response.status === 200 &&
            response?.data.document_type === "Invalid"
          ) {
            await sendLogs(
              "GPT liveFeedbackForm Process",
              "Uploaded document is inavlid! ",
              "uplaods3_Action.js",
              sessionId,
              "4"
            );
          } else {
            await sendLogs(
              "GPT liveFeedbackForm Process",
              "Uploaded document is valid! ",
              "uplaods3_Action.js",
              sessionId,
              "4"
            );
          }
          const chatGptResponse = response?.data;
          dispatch({ type: LIVEFEEDBACK_SUCCESS, payload: chatGptResponse });
          var data = {
            sessionid: sessionId,
            data: {
              s3filename: [uploadParams.Key],
            },
          };
          await dispatch(updateSession(data, sessionId));
        }
      })();

      // Wait for all promises to resolve
      await Promise.all([s3Promise, liveFeedbackPromise]);
    } catch (error) {
      console.error("Error in s3fileHandler:", error);
      var SentryLogMessage = {
        version: "1.0",
        host: "pdf verifier upload Process",
        short_message: "Failed S3 file upload",
        full_message: `S3 Bucket File upload failed.`,
        timestamp: Date.now() / 1000,
        level: 3,
        facility: "uplaods3_Action.js",
        _sessionid: sessionId,
        app_name: "pdf upload Verifier ",
        _ENV: "stage2",
      };
      dispatch({ type: UPLOAD_S3_FILE_FAILURE, payload: error });
      await sendSentryLogs(SentryLogMessage);
      await sendLogs(
        "S3 file upload",
        "Error Found in Upload S3 Process.",
        "uplaods3_Action.js",
        sessionId,
        "4"
      );
    }
  };
};
