import React, { useState } from "react";
import { useSelector } from "react-redux";
import { env as environment } from "../../environments";
import axios from "axios";
import { sendLogs } from "../../helpers/sendLogs";
import "./indexTemplate.css";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Form,
  FormGroup,
  CloseButton,
  FormFeedback,
} from "reactstrap";

function ReportIssue({ modalColor, setModalOpen }) {
  const VerificationLinkPoc = useSelector(
    (state) => state?.verificationLinkPOC?.verificationPocData?.verificationlink
  );
  const nses = VerificationLinkPoc.lastIndexOf("/");
  const sessionId = VerificationLinkPoc.substring(nses + 1);
  const [showExitScreen, setShowExitScreen] = useState(true);
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);

  const handleClose = async () => {
    setShowExitScreen(false);
    setModalOpen(false);
    await sendLogs(
      "Report feedback",
      "Report feedback Popup is closed successfully",
      "reportIssue",
      sessionId,
      "2"
    ); // Use the setter function to close the modal
  };
  const handleEventSubmit = (event) => {
    event.preventDefault();
    let isValid = true;

    if (email === "") {
      setEmailError(true);
      isValid = false;
    } else {
      setEmailError(false);
    }

    if (description === "") {
      setDescriptionError(true);
      isValid = false;
    } else {
      setDescriptionError(false);
    }

    if (isValid) {
      // Proceed with form submission or other actions
    }

    const formData = new FormData(event.target);
    const data = {
      email: formData.get("email"),
      comment: formData.get("text"),
      sessionId: sessionId,
      source: "upload capture process",
    };

    // Call your function with the data payload
    feedbackApiCall(data);
    setEmail("");
    setDescription("");
  };

  const feedbackApiCall = async (data) => {
    const feedbackResponse = await axios.post(environment.feedbackurl, data);
    if (feedbackResponse.status === 200) {
      sendLogs(
        "Report feedback Success",
        " feedback report has been sent. ",
        "reportIssue",
        sessionId,
        "3"
      );
    } else {
      sendLogs(
        "Report feedback Failed",
        " feedback report has not submitted. ",
        "reportIssue",
        sessionId,
        "3"
      );
    }
  };

  return (
    showExitScreen && (
      <div>
        <div
          className="header"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: "rgb(239 245 243 / 67%)",
            alignItems: "center",
          }}
        >
          <h5
            style={{
              fontWeight: "bolder",
              fontFamily: "Montserrat",
              margin: "1rem",
              fontSize: "16px",
            }}
          >
            {" "}
            Report issue{" "}
            <small
              style={{
                fontSize: "10px",
                fontWeight: "700",
                fontFamily: "Montserrat",
              }}
            >
              ({sessionId})
            </small>
          </h5>
          <CloseButton style={{ margin: "1rem" }} onClick={handleClose} />
        </div>
        <div
          className="card-body  text-centered p-4 rounded-2"
          style={{
            width: "475px",
          }}
        >
          <Form onSubmit={handleEventSubmit}>
            <FormGroup
              style={{
                marginTop: "2rem",
                width: "95%",
                marginLeft: "0.75rem",
              }}
            >
              <Input
                id="email"
                name="email"
                placeholder="your email address*"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                invalid={emailError}
                style={{
                  outline: "none",
                  border: "1px solid white",
                  fontSize: "14px",
                  color: "#a09b9b",
                  fontWeight: "600",
                }}
              />
              {emailError && <FormFeedback>Email cannot be blank</FormFeedback>}
            </FormGroup>

            <FormGroup
              style={{
                width: "95%",
                marginLeft: "0.75rem",
                border: "1px solid white",
              }}
            >
              <Input
                id="description"
                name="text"
                type="textarea"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Please describe your issue here"
                invalid={descriptionError}
                style={{
                  border: "1px solid white",
                  fontSize: "14px",
                  color: "#a09b9b",
                  fontWeight: "600",
                }}
              />
              {descriptionError && (
                <FormFeedback>Description cannot be blank</FormFeedback>
              )}
            </FormGroup>
            <Button
              type="submit"
              style={{
                marginLeft: "80%",
                width: "5rem",
                height: "2.5rem",
                background: "black",
                fontFamily: "system-ui",
                fontWeight: "700",
                marginBottom: "2rem",
                marginTop: "0.5rem",
              }}
            >
              Submit
            </Button>
          </Form>
        </div>
      </div>
    )
  );
}

export default ReportIssue;
