import { SESSION_UPDATE,SESSION_FAILURE } from '../actions/actionTypes';

const initialState = {   
    sessionSuccess:null,
    error: null
    
  };


const sessionUpdate = (state = initialState, action) => {

    switch (action.type) {

        case SESSION_UPDATE:
            return {
               ...state,
               sessionSuccess: action.payload,
               error: null,
            }
        case SESSION_FAILURE:
            return {    
                error: action.payload,
                sessionSuccess: null,
            }
        default: return state;

}
}

export default sessionUpdate;

