import { useSelector } from "react-redux";
import { LIVEFEEDBACK_START,LIVEFEEDBACK_SUCCESS,LIVEFEEDBACK_FAILURE, ALL_LIVEFEEDBACK_DATA, LIVEFEEDBACK_INVALID_DOC } from "../actions/actionTypes";

const initialState = {
    feedbackStart: false,
    feedbackSuccess:null,
    allLivefeedbackResponses:[],
    processingLivefeedback:false,
    livefeedbackInvalidDoc:false,
    error: null,
  };



  const LiveFeedBack = (state = initialState, action) => {

 
    switch (action.type) {
      case LIVEFEEDBACK_START:
        return {
          ...state,
          feedbackStart: true,
          feedbackSuccess:null,
          processingLivefeedback:true,
          error: null
        };
      case LIVEFEEDBACK_SUCCESS:
        return {
          ...state,
          feedbackStart:false,
          feedbackSuccess:action.payload,
          processingLivefeedback:false,
          error: null
        };
      case ALL_LIVEFEEDBACK_DATA:
        return {
          ...state,
          allLivefeedbackResponses:action.payload,
          processingLivefeedback:false,
          error: null
        };
      case LIVEFEEDBACK_FAILURE:
        return {
          ...state,
          feedbackStart:false,
          processingLivefeedback:false,
          feedbackSuccess:null,
          error: action.payload
        };
        case LIVEFEEDBACK_INVALID_DOC:
          return{
            ...state,
            livefeedbackInvalidDoc:action.payload
          }
      default:
        return state;
    }
  }

  export default LiveFeedBack;

// the below code fragment can be found in: