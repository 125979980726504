import { createStore, applyMiddleware, combineReducers } from "redux";
import { thunk } from "redux-thunk";
import uploadReducer from "../reducers/Uploadreducer";
import uploadS3Reducer from "../reducers/uploadS3Reducer";
import lastLinkClick from "../reducers/lastLinkClickReducer";
import pdfReducerData from "../reducers/pdfFileReducer";
import liveFeedbackData from "../reducers/liveFeedbackReducer";
import verificationLinkPOC from "../reducers/verificationLinkPocReducer";
import sessionUpdate from "../reducers/sessionUpdate";
import oneShotUploadReducer from "../reducers/oneShotUploadReducer";
import { composeWithDevTools } from "redux-devtools-extension";

const rootReducer = combineReducers({
  upload: uploadReducer,
  verificationLinkPOC: verificationLinkPOC,
  lastLinkClicked: lastLinkClick,
  pdfData: pdfReducerData,
  uploadS3: uploadS3Reducer,
  livefeedback: liveFeedbackData,
  sessionUpdate: sessionUpdate,
  oneShotUpload: oneShotUploadReducer,
});

export function configureStore() {
  const composeEnhancers = composeWithDevTools({
    trace: true,
    traceLimit: 25,
  });

  return createStore(rootReducer, applyMiddleware(thunk));
  // return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
}

// import { createStore, applyMiddleware, combineReducers } from 'redux';
// import {thunk} from 'redux-thunk';
// import uploadReducer from '../reducers/Uploadreducer';
// import { composeWithDevTools } from "redux-devtools-extension";
// const composeEnhancers = composeWithDevTools({
//   trace: true,
//   traceLimit: 25
// });
// const rootReducer = combineReducers({
//     upload: uploadReducer,
//     verificationLinkPOC: verificationLinkPOC,
//     lastLinkClicked: lastLinkClick,
//     pdfData: pdfReducerData,
//     uploadS3: uploadS3Reducer,
//     livefeedback: liveFeedbackData,
//     sessionUpdate: sessionUpdate,
//     oneShotUpload: oneShotUploadReducer,

//   });
// export function configureStore(){
//   return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk))
// );
// };
