import React from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { FileUploadSvg } from "../helpers";
import diroLogo from "./assets/dirologo.svg";

export default function Upload({
  handleBrowseClick,
  handleDragOver,
  handleDrop,
  handleFileSelect,
  fileInputRef,
}) {
  const lastLinkClickedData = useSelector(
    (state) => state.lastLinkClicked?.dataSuccess
  );

  const getCategory = (category) => {
    if (category === "address") {
      return "utility bill";
    } else {
      return category + " statement.";
    }
  };

  return (
    <div
      className="card-body  text-centered p-4 rounded-2"
      style={{
        width: "475px",
      }}
    >
      <div
        className="uploader-col"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <div style={{ marginTop: "5px" }}>
          <FileUploadSvg />
        </div>
        <h5 className="mt-2 mb-3" style={{ textAlign: "center" }}>
          {lastLinkClickedData?.button?.multidownload
            ? "Drop your " +
              lastLinkClickedData?.button?.dataRangeInMonths +
              " months " +
              (lastLinkClickedData
                ? getCategory(lastLinkClickedData?.category)
                : "satement.")
            : "Drag & drop or click to upload the latest " +
              (lastLinkClickedData
                ? getCategory(lastLinkClickedData?.category)
                : "statement.")}
        </h5>
        <Button
          className="btn cbtn"
          style={{ padding: "8px 30px" }}
          id="browse"
          onClick={handleBrowseClick}
        >
          Upload
        </Button>
        <input
          ref={fileInputRef}
          className="d-none"
          type="file"
          onChange={handleFileSelect}
          multiple={lastLinkClickedData?.button?.multidownload}
        />
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            fontSize: "13px",
            fontWeight: 600,
          }}
        >
          Powered by
        </div>
        <img src={diroLogo} height={12} alt="dirologo" />
      </div>
    </div>
  );
}
