import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import indexRoutes from "./indexRoutes";
import PageNotFound from "../Components/view/PageNotFound";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        {indexRoutes.map((route, key) => {
          return <Route path={route.path} key={key} element={<route.page />} />;
        })}

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
